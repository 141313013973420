import axios from 'axios';
import LWFiles from '../Files';
import LWUpload from '../Upload';
import User from '../User';
import SnackbarUtils from '../../ui-base/SnackbarUtils';

/**
 * UploadCloud class
 */
export default class UploadCloud extends LWUpload {
    /**
     * Create the upload class
     * @param props
     */
    constructor(props) {
        super(props);
        this.fileType = props.fileType;
        this.path = process.env.APP_MEDIA_URL + 'media/uploadtocloud';
    }

    /**
     * Start process
     */
    start() {
        const root = this;
        if (root.data.fileType === 'image' && root.data.extension !== 'vnd.adobe.photoshop') {
            LWFiles.imageToBase64(root.file, ({ base64 }) => {
                root.broadcast('start', { progress: 0, ...root.data, base64 });
                root.authenticate();
            });
        } else {
            root.broadcast('start', { progress: 0, ...root.data });
            root.authenticate();
        }
    }

    /**
     * Authenticate the user. Gets signed AWS url.
     */
    authenticate() {
        const root = this;
        const { fileName } = this.data;
        const newFilename = fileName.replace(/[^a-zA-Z0-9. ]/g, '');

        // Create the upload url. This will be a signed cloud url.
        axios
            .post(root.path, { filename: newFilename }, { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` } })
            .then((res) => {
                // Remove duplicate
                delete res.data.filename;

                // Also set video url
                if (res.data.videoUrl) {
                    res.data.url = res.data.videoUrl;
                }

                root.data = { ...root.data, ...res.data };
                root.upload();
            })
            .catch((error) => {
                if (401 === error.response.status) {
                    SnackbarUtils.error("You don't have access to this function. ");
                } else {
                    let errorMessage = 'There was an error calling the upload service.';
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    SnackbarUtils.error(errorMessage);
                }

                root.broadcast('error', error);
            });
    }

    /**
     * Upload file using Axios
     */
    upload() {
        const root = this;

        this.broadcast('uploadStart', this.data);
        axios
            .put(root.data.uploadUrl, this.file, {
                headers: {
                    'Content-Type':
                        this.file.type === 'image/svg+xml' || this.file.type === 'video/mp4' || this.file.type === 'application/pdf'
                            ? this.file.type
                            : 'application/octet-stream'
                },
                withCredentials: false,
                onUploadProgress: (e) => {
                    root.data = {
                        ...root.data,
                        progress: Math.ceil((e.loaded / e.total) * 100)
                    };
                    root.broadcast('uploadProgress', root.data);
                }
            })
            .then(() => {
                root.broadcast('processEnd', root.data);
            })
            .catch((error) => {
                root.broadcast('error', error);
            });
    }
}
