import i18n from './i18n';
import isoLanguages from './data/iso-languages.json';

type NameSpaces =
    | 'analytics'
    | 'bricks'
    | 'campaign-overview'
    | 'campaigns'
    | 'common'
    | 'concepts'
    | 'creatives-v2'
    | 'creative-management'
    | 'dashboard'
    | 'editor'
    | 'feed-management'
    | 'interface-builder'
    | 'routes'
    | 'settings'
    | 'template-designer'
    | 'content-space'
    | 'ui-base'
    | 'ui-components'
    | 'sidekick'
    | 'asset-management'
    | 'media-management'
    | 'template-management'
    | 'creative-management-v2'
    | 'asset-gallery-dialog';

type TextTransform = 'upperCase' | 'lowerCase' | 'capitalize';

export default class Translation {
    /**
     * Translate copy using i18next.
     * See docs: https://react.i18next.com/ and https://www.i18next.com/
     * @returns translated string.
     */
    static get(model: string, namespace: NameSpaces = 'common', options?: { textTransform?: TextTransform } & { [key: string]: string | number }): string {
        const { textTransform, ...otherOptions } = options || {};
        const translation: string = i18n.t(model, { ...otherOptions, ns: namespace });
        return this.doTransFormText(translation, textTransform);
    }

    /**
     * Return the translation for the current active language when given an object with translations.
     * There is a fallback in case a string is passed
     * @param {object|string} translations
     * @returns translated string.
     */
    static inline(translations: object | string): string {
        // Find the translation for the current language in an object.
        const translateObject = (value) => {
            const lang = i18n.language;
            if (value[lang]) {
                return value[lang];
            } else if (value.en) {
                return value.en;
            } else return JSON.stringify(value);
        };

        if (typeof translations === 'object') {
            return translateObject(translations);
        } else if (typeof translations === 'string') {
            try {
                const transObject = JSON.parse(translations);
                return translateObject(transObject);
            } catch (error) {
                return translations;
            }
        } else {
            // eslint-disable-next-line no-console
            console.error('Translation failed for', translations);
            return '';
        }
    }

    /**
     * Change the currently used interface language.
     * @param {string} lang
     */
    static changeLanguage(lang: string): void {
        i18n.changeLanguage(lang);

        localStorage.setItem('language-campaigndesigner', lang);
        window.location.reload();
    }

    /**
     * Get current available languages.
     */
    static getLanguages(): string[] {
        return Object.keys(i18n.options.resources ? i18n.options.resources : {});
    }

    /**
     * Get current available languages.
     */
    static getLanguageLabel(lng: string): string {
        return isoLanguages[lng.toUpperCase()];
    }

    /**
     * Get current available languages.
     */
    static getCurrentLanguage(): string {
        return i18n.language;
    }

    static doTransFormText = (text: string, format?: TextTransform | string): string => {
        if (format === 'upperCase') {
            return text.toUpperCase();
        }
        if (format === 'lowerCase') {
            return text.toLowerCase();
        }
        if (format === 'capitalize') {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        }
        return text;
    };
}
